<template>
  <div v-if="hasProduct" class="ProductImportManager">
    <!-- Import summary & display missing definitions prior import -->
    <UiDialog
      :title="$t('product.import-wizard.summary.title')"
      :visible="showSummary"
      width="20%"
      :confirm-label="
        $t('product.import-wizard.summary.confirm-create-product')
      "
      @close="closeSummary()"
      @cancel="cancelImportSummary()"
      @confirm="confirmImportSummary()"
    >
      <template #dialog-content>
        <div>
          <p>{{ $t('product.import-wizard.summary.info') }}</p>
          <div v-for="(item, index) in definitionsByList" :key="index">
            <div v-if="item.unfoundTerms.length > 0" class="content-summary">
              <span class="content-summary__title"
                >{{ index }} ({{ item.unfoundTerms.length }})</span
              >
              <ChipCollector
                v-if="showSummary"
                :items="item.unfoundTerms"
                @click="toggleAction"
              />
            </div>
          </div>
        </div>
      </template>
    </UiDialog>

    <!-- Import manager -->
    <UiDialog
      :visible="visible"
      :confirm-label="$t(labelForProductType('product.import-wizard.create-'))"
      :title="$t(labelForProductType('product.import-wizard.'))"
      :freeze="submitting"
      variant="large"
      @close="onClose()"
      @cancel="cancelImport()"
      @confirm="requestImport()"
    >
      <template #dialog-content>
        <div class="header_subtitle">
          {{ productName }}
          <i class="el-icon-arrow-right"></i>
          <strong>{{ $t('product.import-wizard.subtitle') }}</strong>
        </div>

        <UiLoadingArea :is-loading="showLoadingArea" :message="loadingMessage">
          <div class="content_subtitle">
            <span class="import-file"
              >{{ $t('product.import-wizard.import-file') }}:</span
            >
            <span>{{ importFileName }}</span>
          </div>
          <!-- ///// start tabs -->
          <el-tabs v-model="activeName">
            <el-tab-pane
              :label="$t(labelForProductType('product.import-wizard.'))"
              name="tab-product"
            >
              <div class="form-group col-12">
                <label class="team-attribution">Team Attribution</label>
                <el-select v-model="selectedTeam">
                  <el-option
                    v-for="team in availableTeams"
                    :key="team.id"
                    :label="team.label"
                    :value="team.id"
                  ></el-option>
                </el-select>
              </div>
              <UiButton
                data-test="button-new-definition-list"
                variant="secondary"
                icon="el-icon-plus"
                @click="createNewDefinitionList"
                >{{ $t('product.import-wizard.add-definition-list') }}</UiButton
              >
              <UiButton
                data-test="button-clear-definition-list"
                variant="secondary"
                icon="el-icon-set-up"
                :disabled="clearDefinitionRequested"
                @click="clearDefinitionLists"
              >
                {{ $t('product.import-wizard.clear-definition-list') }}
              </UiButton>

              <div class="pt-4">
                <UiTable
                  v-if="!isLoading"
                  :data="definitionLists"
                  :default-sort="{ prop: 'fieldname', order: 'descending' }"
                  :row-class-name="getRowClass"
                >
                  <template #expand="{ row }">
                    <ChipCollector
                      :items="getDefinitionsByListId(row)"
                      @click="toggleAction"
                    />
                  </template>
                  <el-table-column label="Property">
                    <template #default="{ row, $index }">
                      <UiColorPicker
                        :toggle-editing="false"
                        :value="row.color"
                        :palette="colors"
                        :data-cy="`color-picker-${row.dimensionName}`"
                        data-test="property-color-picker"
                      />

                      <el-tooltip :content="`${row.__type}`">
                        <span :data-cy="`dimension-row-${$index}`">{{
                          row.dimensionName
                        }}</span>
                      </el-tooltip>
                    </template>
                  </el-table-column>

                  <el-table-column label="Definition list" width="300">
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.definitionListId"
                        :disabled="!scope.row.canSelectList"
                        :data-cy="`select-definition-list-${scope.$index}`"
                        data-test="select-definition-list"
                        placeholder="Definition list"
                        filterable
                        @change="updateDefinitionList(scope.row)"
                      >
                        <el-option
                          v-for="item in definitionListsFormatted"
                          :key="item._id"
                          :label="item.name"
                          :value="item._id"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>

                  <el-table-column label="Questionnaire">
                    <template slot-scope="scope">
                      <div style="display: flex; flex-direction: column">
                        <div
                          v-for="(questionnaire, index) in scope.row
                            .questionnaires"
                          :key="index"
                          style="margin-bottom: 0.4rem"
                        >
                          <div
                            style="
                              font-weight: bold;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                          >
                            {{ questionnaire.name }}
                          </div>
                          <el-select
                            v-model="scope.row.questionnaires[index].matchingId"
                            placeholder="Questionnaire"
                            filterable
                            @change="
                              updateQuestionnaire(
                                scope.row,
                                questionnaire,
                                index
                              )
                            "
                          >
                            <el-option
                              v-for="item in questionnairesFormatted"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column>
                    <template slot="header">
                      <div slot="reference">
                        {{ $t('product.import-wizard.unfound-definitions') }}
                      </div>
                      <i class="el-icon-info"></i>
                    </template>
                    <template slot-scope="scope">
                      <UiRatioBadge
                        v-if="getDefsStatsPerDimension(scope.row).total > 0"
                        :var-x="getDefsStatsPerDimension(scope.row).notExisting"
                        :var-y="getDefsStatsPerDimension(scope.row).total"
                        :show-only-total="true"
                      />
                    </template>
                  </el-table-column>
                </UiTable>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="Associated definition lists"
              name="tab-definitions-lists"
            >
              <DefinitionListsOverview
                :definitions-lists="getAssociatedDefinitionsLists()"
                :definition-list-suggestions="definitionListSuggestions"
              />
            </el-tab-pane>
          </el-tabs>
        </UiLoadingArea>
      </template>
    </UiDialog>
  </div>
</template>
<script>
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { orderBy, cloneDeep, noop } from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import { Message, MessageBox } from 'element-ui';
import * as api from '../api';
import { colors } from '../config';
import ChipCollector from './ChipCollector.vue';
import DefinitionListsOverview from './DefinitionLists/DefinitionListsOverview.vue';
import { productTypes } from '../const/product';
import { findAll } from '../store/products/products.actions';

export default {
  name: 'ProductImportManager',
  components: { ChipCollector, DefinitionListsOverview },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    productType: {
      type: String,
      required: true
    },
    productJSON: {
      type: Object,
      required: true
    },
    importFileName: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loading: true,
      initialized: false,
      loadingMessage: null,
      questionnaires: [],
      definitionsByList: {},
      colors,
      submitting: false,
      showSummary: false,
      confirmImport: false,
      syncDisplayName: false,
      clearDefinitionRequested: false,
      activeName: 'tab-product',
      product: null,
      selectedTeam: null
    };
  },
  computed: {
    ...mapState('definitionListSuggestions', {
      definitionListSuggestions: 'suggestions'
    }),
    ...mapState('products', { products: 'list' }),
    ...mapState('definitions', ['definitions']),
    ...mapState('auth', ['tenant']),
    ...mapGetters('sharedProperty', ['dimensions', 'getDimensionByName']),
    ...mapGetters('sharedProperty', ['outputs', 'getOutputByName']),
    ...mapGetters('auth', ['isMember', 'isOwner']),
    ...mapState('teams', ['teams']),

    showLoadingArea() {
      return this.isLoading || this.submitting;
    },
    hasProduct() {
      return !!this.product;
    },
    isLoading() {
      return (
        !this.product.specification ||
        !!this.confirmImport ||
        this.definitionListSuggestions.length === 0 ||
        this.loading === true
      );
    },
    productName() {
      return this.product.name;
    },
    definitionListsFormatted() {
      return orderBy(
        [
          ...this.definitionListSuggestions.map((dl) => ({
            _id: dl.key,
            name: dl.label
          })),
          { _id: 0, name: `<${this.$t('product.import-wizard.no-list')}>` }
        ],
        [(definition) => definition.name.toLowerCase(), ['desc']]
      );
    },
    definitionLists() {
      const matchDefinitionListWithProperty = (property) => {
        const listFound = this.definitionListsFormatted.find((dff) => {
          // Here list with no definition list name (export v1 wo/package.json) returns always 0. As we only want to select list based on id+name
          return !this.initialized
            ? dff._id === property.definitionListId &&
                dff.name.toUpperCase() ===
                  property.definitionListName.toUpperCase()
            : dff._id === property.definitionListId;
        });
        return listFound;
      };

      const formatPropertyDefinitionList = (property) => {
        // clean definition list attribute
        const dlIds = [
          property.definition_list_id || 0,
          property.definitionListId || 0
        ];
        return dlIds.reduce((acc, curval) => {
          return curval !== 0 ? curval : acc;
        }, 0);
      };

      try {
        const list = [];

        if (
          !this.product.specification ||
          !this.product.specification.dimensions
        ) {
          return list;
        }

        const fetchDefinitionListName = (definitionListId) => {
          const defList = this.product._associatedContent
            ? this.product._associatedContent.find(
                (c) =>
                  c.type === 'DEFINITION-LIST' &&
                  parseInt(c.metadata.id, 10) === definitionListId
              )
            : null;

          if (defList) {
            return defList.metadata.name;
          }
          return '';
        };

        const properties = Object.keys(
          this.product.specification.dimensions
        ).concat(Object.keys(this.product.specification.out));

        const { specification } = this.product;
        // loop over dimension
        properties.map((d) => {
          const dimension = specification.dimensions[d]
            ? specification.dimensions[d]
            : specification.out[d];
          const allowedTypes = ['DIMENSION', 'OUTPUT'];
          if (
            !allowedTypes.includes(dimension.__type) ||
            (dimension.__type === 'OUTPUT' && dimension.type !== 'Text')
          ) {
            return false;
          }
          Object.assign(dimension, { name: dimension.name || d });

          dimension.definitionListId = formatPropertyDefinitionList(dimension);
          if (dimension.definition_list_id) {
            delete dimension.definition_list_id;
          }

          if (dimension.definitionListId !== 0) {
            // initialize definition list poperty of the dimension
            this.syncTermPerDimension(d, dimension.definitionListId);

            // find the definition based on the id + name (only id to support retro-compatibility)
            Object.assign(dimension, {
              definitionListName: fetchDefinitionListName(
                dimension.definitionListId
              )
            });

            const listFound = matchDefinitionListWithProperty(dimension);

            if (listFound === undefined) {
              dimension.definitionListId = 0;
            }
          }
          // store each questionnaire and its position (in the product)
          const questionnaires = [];
          const questionnairesForDimension = [];
          if (this.product.questionnaires) {
            this.product.questionnaires.forEach((q, idx) => {
              if (
                q.trigger.dimension &&
                q.trigger.dimension.name &&
                q.trigger.dimension.name === dimension.name
              ) {
                const matchingId =
                  (
                    this.questionnaires.find(
                      (q2) => q2.id === q.id && q2.name === q.name
                    ) || {}
                  ).id || 0;
                if (!this.initialized && matchingId === 0) {
                  this.product.questionnaires.map((q2) => {
                    if (q2.id === q.id) {
                      Object.assign(q2, { id: 0 });
                    }
                    return q2;
                  });
                }
                questionnaires.push({
                  matchingId,
                  originalId: matchingId === 0 ? 0 : q.id, // this is used to disable questionnaire selection if no original questionnaire
                  name: q.name
                });
                questionnairesForDimension.push({
                  idx,
                  q: { ...q, id: matchingId }
                });
              }
            });
          }
          let sharedProperty;
          if (dimension.__type === 'DIMENSION') {
            sharedProperty = this.getDimensionByName(d);
          } else if (dimension.__type === 'OUTPUT') {
            sharedProperty = this.getOutputByName(d);
          }
          if (sharedProperty && sharedProperty.definitionListId) {
            Object.assign(dimension, {
              definitionListId: sharedProperty.definitionListId
            });
          }
          // returns table list
          list.push({
            __type: dimension.__type,
            color: dimension.color,
            definitionListId: dimension.definitionListId,
            dimensionName: d,
            questionnaires,
            questionnairesForDimension,
            canSelectList: !sharedProperty
          });
        });

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.initialized = true;

        list
          .filter((dl) => dl.definitionListId > 0)
          .forEach((dl) => {
            this.fetchDefinitionsByList(dl);
          });

        return list;
      } catch (err) {
        Message.error(
          this.$t('product.import-wizard.failed-reading-importfile')
        );
        return this.cancelImport();
      }
    },
    questionnairesFormatted() {
      return orderBy(
        [...this.questionnaires, { id: 0, name: '<no questionnaire>' }],
        [(questionnaire) => questionnaire.name.toLowerCase(), ['desc']]
      );
    },
    availableTeams() {
      return (
        this.teams &&
        this.tenant &&
        this.teams.filter(
          (t) =>
            t.tenant === this.tenant &&
            (this.isMember(t.slug) || this.isOwner(this.defaultTeam.slug))
        )
      );
    },
    defaultTeam() {
      return (
        this.teams &&
        this.tenant &&
        this.teams.find((t) => t.slug === this.tenant)
      );
    }
  },
  watch: {
    async productJSON(value) {
      this.loading = true;
      this.product = cloneDeep(value);
      if (!this.product.id) {
        this.loading = false;
        return;
      }
      this.questionnaires = await this.fetchQuestionnaires();
      this.loading = false;
    },
    async visible(value) {
      if (value === true && this.definitions.length === 0) {
        await this.fetchDefinitions();
      }
    },
    teams() {
      this.setDefaultTeam();
    }
  },
  mounted() {
    this.setDefaultTeam();
    this.fetchSharedProperties();
  },
  methods: {
    ...mapActions('definitionListSuggestions', { fetchDefinitions: 'fetch' }),
    ...mapActions('sharedProperty', ['fetchSharedProperties']),
    ...mapActions('definitions', ['fetchItemsForDefinition']),
    ...mapActions('products', ['fetchAllProducts']),

    async fetchQuestionnaires() {
      const { data } = await findAll({
        tenant: this.tenant,
        sort: {
          field: 'name',
          direction: 'ASC'
        },
        type: productTypes.QUESTIONNAIRE,

        as: 'simple-search'
      });

      return data;
    },
    // summary
    closeSummary() {
      this.showSummary = false;
    },
    cancelImportSummary() {
      this.closeSummary();
    },
    confirmImportSummary() {
      this.confirmImport = true;
      this.requestImport();
      this.closeSummary();
    },
    // dialog handler
    onClose() {
      this.definitionsByList = {};
      this.syncDisplayName = false;
      this.confirmImport = false;
      this.loadingMessage = null;
      this.initialized = false;
      this.$emit('close');
    },
    cancelImport() {
      this.onClose();
    },
    cleanupTermsFromOrphanList() {
      Object.keys(this.product.specification.dimensions).forEach(
        (dimension) => {
          if (
            this.product.specification.dimensions[dimension]
              .definitionListId === 0
          ) {
            delete this.product.terms[dimension];
          }
        }
      );
    },
    cleanupUnselectedDefinitions() {
      const deleteDefinitionFromProduct = (dimension, termId, dfId) => {
        const term = this.product.terms[dimension].find(
          (t) => t._id === termId
        );

        Object.assign(term, {
          definitions: term.definitions.filter((df) => df._id !== dfId)
        });
      };

      Object.keys(this.definitionsByList).forEach((dimension) => {
        if (this.product.terms[dimension]) {
          this.definitionsByList[dimension].unfoundTerms.forEach((df) => {
            if (!df.selected) {
              deleteDefinitionFromProduct(dimension, df.termId, df.id);
            }
          });
        }
      });
    },
    cleanupUnselectedQuestionnaires() {
      Object.assign(this.product, {
        questionnaires: this.product.questionnaires.filter((q) => q.id !== 0)
      });
    },
    cleanupUndoStackFromRules(graph) {
      // eslint-disable-next-line no-param-reassign
      delete graph.undoStack;
      if (graph.children) {
        graph.children.forEach((ch) => {
          this.cleanupUndoStackFromRules(ch);
        });
      }
    },
    async requestImport() {
      if (!this.confirmImport && this.hasUnfoundTerms()) {
        this.showSummary = true;
        return;
      }

      this.submitting = true;

      try {
        this.cleanupTermsFromOrphanList();

        this.cleanupUnselectedDefinitions();

        this.cleanupUnselectedQuestionnaires();

        (this.product.rules || []).forEach((r) =>
          this.cleanupUndoStackFromRules(r.graph)
        );

        this.loadingMessage = this.$t(
          'product.import-wizard.import-processing'
        );
        // send json product to backend
        const blob = new Blob([JSON.stringify(this.product, null, 2)], {
          type: 'application/json'
        });
        const { data } = await api.importProduct(
          blob,
          this.productType,
          this.tenant,
          this.selectedTeam
        );

        if (!data.ok) {
          return Message.error(data.message);
        }

        this.$emit('close', data);
      } catch (err) {
        let closeModal = false;
        let message = err.message
          ? err.message
          : `An error occurred during the import. Please verify that product is of type ${this.product.type}`;

        if (typeof err === 'string') {
          if (err.includes('504 ERROR')) {
            closeModal = true;
            message = this.$t(
              'product.import-wizard.import-processing-notif-user'
            );
          }
        } else {
          Message.error(message);
        }

        if (closeModal) {
          setTimeout(() => {
            Message.info({ message, duration: 7000 });
            this.submitting = false;
            this.$emit('close');
          }, 3000);
        } else {
          this.submitting = false;
        }
      }

      return noop;
    },
    // toggleAction is performed on an existing definitions bucket (definitionsByList) where all definitions
    // from target list and definitions from dimension related list are unified.
    // toggleAction selected / deselect definition to import
    toggleAction(item) {
      if (!item.editable) {
        return;
      }
      const { list, unfoundTerms } = this.definitionsByList[item.dimension];
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].id === item.id) {
          list[i].selected = !list[i].selected;
          break;
        }
      }
      for (let i = 0; i < unfoundTerms.length; i += 1) {
        if (unfoundTerms[i].id === item.id) {
          unfoundTerms[i].selected = !unfoundTerms[i].selected;
          break;
        }
      }
    },
    updateDefinitionList({ dimensionName, definitionListId }) {
      const { specification } = this.product;
      const property = specification.dimensions[dimensionName]
        ? specification.dimensions[dimensionName]
        : specification.out[dimensionName];

      property.definitionListId = definitionListId;
      this.syncTermPerDimension(dimensionName, definitionListId);
    },
    // on list change, re-sync all definition list from terms for a given dimension
    syncTermPerDimension(dimension, definitionListId) {
      if (this.product.terms[dimension]) {
        this.product.terms[dimension].forEach((term) => {
          term.definitions.forEach((definition) => {
            const listFound = this.definitionListsFormatted.find(
              // eslint-disable-next-line no-underscore-dangle
              (dl) => dl._id === definitionListId
            );

            if (listFound === undefined) {
              Object.assign(definition.definitionList || {}, {
                name: 'NOT-FOUND',
                id: 0
              });
            } else {
              // eslint-disable-next-line no-param-reassign
              definition.definitionList = definition.definitionList || {};
              Object.assign(definition.definitionList, {
                name: listFound.name,
                id: definitionListId
              });
            }
          });
        });
      }
    },

    getDefinitionsByListId(row) {
      return (
        (this.definitionsByList[row.dimensionName] || {}).unfoundTerms || []
      );
    },

    async fetchDefinitionsByList({ definitionListId, dimensionName }) {
      const unfoundTerms = [];
      // noinspection JSValidateTypes
      const definitions = await this.fetchItemsForDefinition({
        definitionListId
      });
      const definitionsFromList = definitions.map((d) => ({
        id: d._id,
        name: d.primaryKey,
        primaryKey: d.primaryKey,
        dimension: dimensionName,
        selected: false,
        editable: false
      }));

      const definitionsFromDimension =
        this.getDefinitionsForDimensions(dimensionName);

      // for each dimensions definition:
      //   check if this definition exists in the current definition list
      //     if no, add the definition with property 'existingInList' set to FALSE
      //      Param existingInList was used to select / deselect definition on the summary panel - on hold till release 0.26
      //      Param new (d.new) has been added to distinguish new definition name added than the existing ones. Especially the case
      //        where two definitions have the same name (Andorra) but under different term (Andorra / Worldwide except UK),
      //        this prevent from a term to be ignored from definition cleanup (and potentially lead to import definitions by mistake)
      const existDefinition = (dimDefinition) => {
        const foundDefinition = definitionsFromList.find(
          (d) => d.primaryKey === dimDefinition.primaryKey && !d.new
        );
        return !!foundDefinition;
      };

      definitionsFromDimension.forEach((df) => {
        // release 0.26 : set selected and editable to TRUE
        if (!existDefinition(df)) {
          definitionsFromList.push({
            ...df,
            selected: false,
            editable: false,
            new: true
          });
          unfoundTerms.push({ ...df, selected: false, editable: false });
        }
      });

      this.$set(this.definitionsByList, dimensionName, {
        list: definitionsFromList,
        unfoundTerms
      });
    },

    getDefinitionsForDimensions(dimension) {
      // loops all terms under the dimension and collect all definitions
      const definitions = [];
      const terms = this.product.terms[dimension] || null;

      if (!terms) {
        return definitions;
      }

      // loop through terms, all deflists are sync according to the dimension current definition list Id
      for (let j = 0; j < terms.length; j += 1) {
        const term = terms[j];

        if (Array.isArray(term.definitions)) {
          for (let k = 0; k < term.definitions.length; k += 1) {
            definitions.push({
              termId: term._id,
              termName: term.name,
              primaryKey: term.definitions[k].primaryKey,
              id: term.definitions[k]._id,
              name: term.definitions[k].primaryKey,
              dimension
            });
          }
        }
      }
      return definitions;
    },
    updateQuestionnaire(
      { questionnaires, questionnairesForDimension },
      oldQId,
      idxOldQuestionnaire
    ) {
      const { matchingId } = questionnaires[idxOldQuestionnaire];
      const productQListIndex =
        questionnairesForDimension[idxOldQuestionnaire].idx;

      const replaceQuestionnaire = this.questionnaires.find(
        (q) => q.id === matchingId
      );
      if (!replaceQuestionnaire) {
        Object.assign(this.product.questionnaires[productQListIndex], {
          id: 0
        });
        return;
      }

      Object.assign(this.product.questionnaires[productQListIndex], {
        id: replaceQuestionnaire.id,
        name: replaceQuestionnaire.name,
        output_key: replaceQuestionnaire.name
          .replace(/[^\w]/gi, '_')
          .toUpperCase()
          .slice(0, 255)
      });
    },
    getDefsStatsPerDimension({ dimensionName }) {
      const total = (
        (this.definitionsByList[dimensionName] || {}).unfoundTerms || []
      ).length;
      const imported = (
        (this.definitionsByList[dimensionName] || {}).unfoundTerms || []
      ).filter((df) => !df.selected).length;
      return {
        notExisting: total - imported,
        total
      };
    },
    clearDefinitionLists() {
      this.clearDefinitionRequested = true;
      Object.keys(this.product.specification.dimensions).forEach((d) => {
        this.product.specification.dimensions[d].definitionListId = 0;
        this.product.specification.dimensions[d].definitionListName = null;
        this.syncTermPerDimension(d, 0);
      });
      Object.keys(this.product.specification.out).forEach((d) => {
        this.product.specification.out[d].definitionListId = 0;
        this.product.specification.out[d].definitionListName = null;
        this.syncTermPerDimension(d, 0);
      });
      this.clearDefinitionRequested = false;
    },
    async createNewDefinitionList() {
      try {
        const { value } = await MessageBox.prompt(
          this.$t('definition-lists.name'),
          this.$t('definition-lists.create')
        );
        if (value == null) {
          await Message.error(this.$t('definition-lists.failed-create'));
          return noop;
        }
        // eslint-disable-next-line no-unused-vars
        await api.createDefinitionList(value, this.tenant);
        // noinspection JSValidateTypes
        this.fetchDefinitions();
        Message.success('List successfully created.');
        return noop();
      } catch (err) {
        return noop();
      }
    },
    getRowClass({ row, rowIndex }) {
      const { dimensionName, definitionListId } = row;
      return `row-index-${rowIndex} row-import-wizard-dimension dimension-${dimensionName} listId-${definitionListId}`;
    },
    hasUnfoundTerms() {
      const dimensions = Object.keys(this.definitionsByList);
      for (let i = 0; i < dimensions.length; i += 1) {
        if (this.definitionsByList[dimensions[i]].unfoundTerms.length > 0) {
          return true;
        }
      }
      return false;
    },
    getAssociatedDefinitionsLists() {
      return (this.productJSON._associatedContent || []).filter(
        (c) => c.type === 'DEFINITION-LIST'
      );
    },
    labelForProductType(labelBase) {
      return `${labelBase}${this.productType.toLowerCase()}`;
    },
    setDefaultTeam() {
      if (this.availableTeams && this.availableTeams.length) {
        const foundDefaultTeam = this.availableTeams.find(
          (t) => t.slug === this.tenant
        );
        this.selectedTeam = foundDefaultTeam
          ? foundDefaultTeam.id
          : this.availableTeams[0].id;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.ProductImportManager {
  &::v-deep {
    .el-table th,
    .el-table td {
      vertical-align: top;
    }
  }
}
.header_subtitle {
  position: absolute;
  z-index: 100000;
  top: 3px;
  margin-top: 15px;
  left: 139px;
}
.content-summary {
  margin-bottom: 20px;
}
.content_subtitle {
  margin: 10px 0;
}
.import-file {
  font-weight: bold;
}

.content-summary__title {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.team-attribution {
  margin-right: 15px;
}
</style>
