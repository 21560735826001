<template>
  <div :class="classNames">
    <UiLoadingArea :is-loading="isLoading || isCopyingProduct">
      <ProductTable
        :products="products"
        :product-type="productType"
        :default-sort="sortBy"
        @delete="$emit('delete')"
        @sort-change="(field, order) => $emit('sort-change', field, order)"
        @copying-product="handleCopyState"
      />
      <UiPagination
        v-if="showPagination"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :total="total"
      />
    </UiLoadingArea>
  </div>
</template>

<script>
import ProductTable from '../ProductTable';
import { productTypes } from '../../const/product';

export default {
  name: 'PaginatedProductTable',
  components: {
    ProductTable
  },
  props: {
    isReadOnlyMode: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 25
    },
    sortBy: {
      type: Object,
      required: true
    },
    products: {
      type: Array,
      default: () => []
    },
    productType: {
      type: String,
      required: true,
      validator: (value) => !!productTypes[value]
    },
    variant: {
      type: String,
      validator(value) {
        return ['default'].includes(value);
      },
      default: () => 'default'
    }
  },
  data: () => {
    return {
      currentPage: 1,
      isCopyingProduct: false
    };
  },
  computed: {
    classNames() {
      return {
        PaginatedProductTable: true
      };
    },
    showPagination() {
      return this.total > this.pageSize;
    }
  },
  watch: {
    currentPage() {
      this.$emit('page-change', this.currentPage);
    }
  },
  mounted() {
    this.currentPage = this.page || 1;
  },
  methods: {
    handleCopyState(val) {
      this.isCopyingProduct = val;
    }
  }
};
</script>
