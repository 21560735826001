/**
 * Copyright 2020 AXA Team Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import _ from 'lodash';
import DataLoader from 'dataloader';
import { LRUMap } from 'lru_map';
import feathersClient from '../../feathers';
import * as api from '../../api';
import types from './definitions.types';

const fetchDefinitionListValidations = async (ids) => {
  const definitionListIds = ids.map((id) => parseInt(id.split(':')[0], 10));
  const tenant = ids[0].split(':')[1];
  const isTeamEqual = ids.every((id) => id.split(':')[1] === tenant);
  if (!isTeamEqual) {
    throw Error(
      'Error fetching definition list validation: teams are not equal'
    );
  }
  const id = definitionListIds[0];
  if (!tenant)
    throw Error('Error fetching definition list validation: tenant is missing');

  return feathersClient.service('2.0/definition-list').get(id, {
    query: {
      tenant,
      validation: true,
      batch: definitionListIds.join(',')
    }
  });
};

const definitionListValidationLoader = new DataLoader(
  fetchDefinitionListValidations,
  {
    maxBatchSize: 40,
    cacheMap: new LRUMap(150)
  }
);

const actions = {
  reset({ commit }) {
    commit(types.RESET);
  },
  async importDefinitonsList({ commit, rootState }, { data, sync }) {
    commit(types.OPERATION_START);
    const result = await api.importDefinitionList(data, rootState.auth.tenant);
    commit(types.OPERATION_SUCCESS);
    if (sync) {
      await this.fetchDefinitions();
    }
    return result;
  },
  async fetchDefinitions({ commit, rootState }) {
    commit(types.OPERATION_START);
    try {
      const data = await api.getDefinitionLists({
        tenant: rootState.auth.tenant
      });
      if (data)
        commit(types.LOAD_STORE, { lists: _.sortBy(data.items, 'name') });
      commit(types.OPERATION_SUCCESS);
    } catch (error) {
      commit(types.OPERATION_FAILURE, error.response);
    }
  },
  async fetchItemsForDefinition(
    { state, commit, rootState },
    { definitionListId }
  ) {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve, reject) => {
      if (!definitionListId) {
        reject(new Error('Missing definition list'));
      }

      const checkDefinitionItemsLoading = async () => {
        if (state.definitionItems[definitionListId]) {
          resolve(state.definitionItems[definitionListId]);
          return;
        }

        if (state.definitionItemsLoading[definitionListId]) {
          setTimeout(checkDefinitionItemsLoading, 200);
        } else {
          commit(types.SET_DEFINITION_ITEMS_REQUEST, {
            definitionId: definitionListId
          });

          await api
            .getDefinitionList(definitionListId, {
              tenant: rootState.auth.tenant
            })
            .then(({ data }) => {
              commit(types.SET_DEFINITION_ITEMS_SUCCESS, {
                definitionId: definitionListId,
                items: data.items || []
              });
              return resolve(data.items || []);
            })
            .catch(() => {
              commit(types.SET_DEFINITION_ITEMS_SUCCESS, {
                definitionId: definitionListId,
                items: []
              });
              resolve([]);
            });
        }
      };

      checkDefinitionItemsLoading();
    });
  },
  async saveDefinitionListTeam(
    // eslint-disable-next-line no-empty-pattern
    {},
    { definitionListId, currentTeam, newTeamId }
  ) {
    return api.updateDefinitionListTeam(
      definitionListId,
      currentTeam,
      newTeamId
    );
  },
  async fetchDefinitionListValidation(
    { commit, rootState },
    { definitionListId }
  ) {
    const { tenant } = rootState.auth;
    if (!tenant) return;
    const id = `${definitionListId}:${rootState.auth.tenant}`;
    definitionListValidationLoader.load(id).then((validation) => {
      commit(types.SET_DEFINITIONLIST_VALIDATION, {
        definitionListId: validation.id,
        validation
      });
    });
  },
  clearDefinitionsListsValidation() {
    definitionListValidationLoader.clearAll();
  }
};

export default actions;
